<script lang="ts" context="module">
  import { type IconName } from "@elements/Icon.svelte";
  import { type Content } from "../content/Content.svelte";

  export interface NavItemProps {
    title: string;
    url?: string;
    links?: NavItemProps[];
    bottomLink?: NavItemProps;
    id?: string;
    subLinks?: NavItemProps[];
    iconName?: IconName;
    featuredMediaItem?: Content;
    onlyUnderMobileTab?: "more";
    underMobileTab?: "more";
    mobileTitle?: string;
    order?: number;
  }
</script>

<script lang="ts">
  import { activeSubmenu } from "./navigationStore.ts";
  export let title: string;
  export let url: string | null = null;
  export let links: NavItemProps[] = [];
  export let isSublink = false;
  export let id: string;
  $: id = id || title.toLowerCase().replace(/\s/g, "-");
</script>

{#if url}
  <a
    tabindex={$$restProps.tabindex}
    class={`${!isSublink ? "uppercase text-white hover:text-p300" : ""}`}
    href={url}
    {...$$restProps}
    >{title}
  </a>
{:else}
  <button
    tabindex={$$restProps.tabindex}
    aria-haspopup="true"
    aria-expanded={$activeSubmenu === id}
    aria-controls={id}
    id={`button_${id}`}
    class={`${!isSublink ? "uppercase text-white hover:text-p300" : ""}`}
    on:click|stopPropagation={() => {
      if ($activeSubmenu === id) {
        activeSubmenu.set("");

        return;
      }
      document.getElementById(id + "_0")?.focus();
      activeSubmenu.set(id);
    }}
    >{title}
    {links && links.length ? "+" : ""}
  </button>
{/if}
