<script lang="ts">
  import { appendIds } from "./Navigation.svelte";
  import Button from "../buttons/Button.svelte";
  import { activeSubmenu } from "./navigationStore";
  export let isActive = false;
  export let activeLink: any = [];
  import MediaItemSquare from "../content/ContentSquare.svelte";
  import { clickOutside } from "../../svelte/actions/clickOutside.ts";

  $: isActive = $activeSubmenu === activeLink.title.toLowerCase();
</script>

<div
  class={` transition-all duration-200 transform translate-y-5 ${isActive ? "translate-y-0 opacity-100 visible" : "translate-y-5 opacity-0 invisible"}`}
  style="
    position:absolute;
            width: 0;
            height: 0;
            border-left: 25px solid transparent;
            border-right: 25px solid transparent;
            border-bottom: 25px solid white;
            "
></div>

<svelte:window
  on:keyup={(event) => {
    switch (event.key) {
      case "Escape":
        document.getElementById(`button_${$activeSubmenu}`)?.focus();

        activeSubmenu.set("");
        break;
      default:
        break;
    }
  }}
/>
<div
  use:clickOutside
  on:outsideclick={() => {
    if ($activeSubmenu) {
      activeSubmenu.set("");
    }
  }}
  id={activeLink.id}
  class={`left-0 w-full absolute h-0 mt-6 transition-all duration-200 transform translate-y-5 ${isActive ? "translate-y-0 opacity-100 visible" : "translate-y-5 opacity-0 invisible"}`}
>
  <div
    class="flex max-w-[909px] m-auto rounded-2xl px-14 py-10 bg-white text-black"
  >
    <ul class="list-none w-2/3 relative pl-0">
      {#if activeLink.links && activeLink.links.length > 0}
        {#each appendIds(activeLink.links) as link, i}
          <li class="mb-3">
            <Button
              id={`${activeLink.id}_${i}`}
              text={link.title}
              href={link.url}
              variant="nav"
            />
          </li>
        {/each}
      {/if}
      {#if activeLink.bottomLink}
        <li class="absolute bottom-0 border-t pr-48 pt-4">
          <Button
            href={activeLink.bottomLink?.url}
            text={activeLink.bottomLink?.title}
            variant="nav"
            slot="lower-link"
            rightIcon={{ name: "arrow-right", width: "15", height: "15" }}
          />
        </li>
      {/if}
    </ul>
    {#if activeLink.featuredMediaItem}
      <MediaItemSquare props={activeLink.featuredMediaItem} />
    {/if}
  </div>
</div>
