<script lang="ts" context="module">
  export function appendIds(items: NavItemProps[]) {
    return items.map((item) => ({
      ...item,
      id: item.title.toLowerCase().replace(/\s/g, "-"),
    }));
  }
</script>

<script lang="ts">
  import NavItem, { type NavItemProps } from "./NavItem.svelte";
  import Submenu from "./Submenu.svelte";
  import Icon from "@elements/Icon.svelte";
  import NavDrawer from "./NavDrawer.svelte";

  let navItems: NavItemProps[] = [
    {
      title: "Media",
      iconName: "playOutline",
      links: [
        {
          title: "Our Podcasts",
          url: "/podcasts",
          featuredMediaItem: {
            seriesTitle: "Most Recent Podcast",
            title:
              "Believing in God \nIsn't the Same as \nHaving a Life in God",
            url: "/podcasts",
            imageUrl:
              "https://wildatheart.b-cdn.net/wp-app/uploads/2024/04/15135204/dilemma-of-christmas-expectations-scaled.jpeg",
          },
        },
        {
          title: "Group Video Experiences",
          url: "/events#video-series",
        },
        {
          title: "Films & Documentaries",
          url: "/rhplay",
        },
        {
          title: "Video & Audio Archive",
          url: "/rhplay",
        },
        {
          title: "Articles & Newsletters",
          url: "/blogs",
        },
      ],
      bottomLink: {
        title: "All Media Types",
        url: "/rhplay",
      },
      featuredMediaItem: {
        seriesTitle: "Recommended for You",
        title: "Wild at Heart \nThe Series \n The Trailer",
        url: "/the-series",
        imageUrl:
          "https://wildatheart.b-cdn.net/wp-app/uploads/2024/04/22140229/b1ca764fdd5e5959.jpeg",
      },
    },
    {
      title: "Events",
      iconName: "locationOutline",
      links: [
        {
          title: "Events for Men",
          url: "/events#for-men",
        },
        { title: "Events for Women", url: "/events#for-women" },
        { title: "Multi-Day Retreats", url: "/events#retreats" },
        { title: "Group Video Series", url: "/events#video-series" },
      ],
      bottomLink: { title: "All Event Types", url: "/events" },
      featuredMediaItem: {
        seriesTitle: "Find Local Events",
        title: "Search Your Region",
        url: "https://local.wildatheart.org",
        imageUrl:
          "https://wildatheart.b-cdn.net/wp-app/uploads/2024/04/23221137/5501ee54aca5fd7c-1000x1000-1.png",
      },
    },
    {
      title: "Daily Practices",
      iconName: "sunOutline",
      mobileTitle: "Daily",
      url: "/#daily-practices",
    },
    { title: "Give", iconName: "heart", url: "/give" },
    {
      title: "Books",
      underMobileTab: "more",
      iconName: "book",
      url: "/books",
    },
    {
      title: "Account",
      underMobileTab: "more",
      iconName: "user",
      url: "https://my.wildatheart.org/wah/profile",
      order: 1,
    },
    {
      title: "Help & Support",
      onlyUnderMobileTab: "more",
      iconName: "settings",
      url: "https://help.wildatheart.org",
      order: 2,
    },
  ];
  let innerWidth = 0;
</script>

<svelte:window bind:innerWidth />

<nav
  id="mainNav"
  aria-label="Site Navigation"
  class="fixed md:static bottom-0 left-0 w-full px-4"
>
  {#if innerWidth >= 768}
    <ul
      id="menu-primary-menu"
      class=" invisible hidden md:visible list-none md:flex justify-center gap-3 lg:gap-6 xl:gap-10"
    >
      {#each appendIds(navItems).filter((item) => !item.onlyUnderMobileTab) as item, i}
        <li class="font-bold text-body-base tracking-wide">
          <NavItem {...item} id={`${item.id}`} />
          {#if item.links}
            <Submenu activeLink={item} />
          {/if}
        </li>
      {/each}
    </ul>
  {:else}
    <ul
      class="md:hidden md:invisible p-0 flex list-none bg-matt-grey/75 rounded-xl justify-evenly backdrop-blur-[2px]"
    >
      {#each appendIds(navItems) as item, i}
        {#if !item.onlyUnderMobileTab && !item.underMobileTab}
          {#if item.links}
            <NavDrawer
              title={item.mobileTitle ?? item.title}
              links={item.links}
              iconName={item.iconName}
            />
          {:else}
            <li class="tracking-wide">
              <a
                href={item.url}
                class="p-4 text-white text-body-small stroke-white fill-white flex flex-col items-center gap-1"
                >{#if item.iconName}
                  <Icon name={item.iconName} />
                {/if}{item.mobileTitle ?? item.title}</a
              >
            </li>
          {/if}
        {/if}
      {/each}
      <NavDrawer
        title="More"
        links={navItems
          .filter(
            (item) =>
              item.onlyUnderMobileTab === "more" ||
              item.underMobileTab === "more",
          )
          .sort((a, b) => (a.order || 0) - (b.order || 0))}
        iconName="hamburger"
      />
    </ul>
  {/if}
</nav>
